<template>
  <div class="doodle-background">
    <v-layout>
      <div v-if="store.state.isLoggedIn">
        <v-app-bar class="primary" density="compact">
          <template v-if="$vuetify.display.xs" v-slot:prepend>
            <v-app-bar-nav-icon @click="showMainNavigation = !showMainNavigation">
              <v-icon v-if="!showMainNavigation">fas fa-regular fa-bars</v-icon>
              <v-icon v-if="showMainNavigation">fas fa-regular fa-xmark</v-icon>
            </v-app-bar-nav-icon>
          </template>
          <v-app-bar-title color="primary">Prasiddhi Forest Foundation</v-app-bar-title>
          <template v-slot:append>
            <v-btn variant="plain" icon="fas fa-light fa-cart-shopping" @click="openCartDrawer"></v-btn>
            <v-btn @click="logoutUser" variant="plain" class="text-lowercase">
              Logout
              <v-icon end icon="fas fa-solid fa-arrow-right-from-bracket"></v-icon>
            </v-btn>
          </template>
        </v-app-bar>
        <v-navigation-drawer permanent location="left" v-if="!$vuetify.display.xs || showMainNavigation">
          <template v-slot:prepend>
            <router-link to="/dashboard"><v-list-item lines="one" prepend-icon="fas fa-light fa-credit-card"
                title="Dashboard"></v-list-item></router-link>
            <v-divider></v-divider>
            <router-link to="/buy-new-trees"><v-list-item lines="one" prepend-icon="fas fa-regular fa-cart-shopping"
                                title="Buy New Trees"></v-list-item></router-link>
            <v-divider></v-divider>
            <v-list-item lines="one" prepend-icon="fas fa-light fa-gift" title="Gift your Trees"></v-list-item>
            <v-divider></v-divider>
            <v-list-item lines="one" prepend-icon="fas fa-light fa-award" title="Tree Certificates"></v-list-item>
            <v-divider></v-divider>
            <v-list-item lines="one" prepend-icon="fas fa-light fa-bag-shopping" title="My Orders"></v-list-item>
          </template>

          <v-divider></v-divider>
        </v-navigation-drawer>
        <CartDrawer />
      </div>
      <MenuHeader />
      <v-main>
        <router-view />
        <v-dialog v-model="store.state.showLoader" width="auto" @click:outside="false">
          <v-progress-circular :size="80" :width="7" color="greenColor" indeterminate></v-progress-circular>
        </v-dialog>
      </v-main>
    </v-layout>
  </div>
</template>
<script setup>
import { ref, inject} from "vue";
import { apiLogout } from "@/components/utils/constant";
import { setCookie } from "@/components/utils/utils";
import MenuHeader from '@/components/Atoms/MenuHeader.vue'
import CartDrawer from "@/components/Pages/BuyNewTrees/components/CartDrawer.vue";
const showMainNavigation = ref(false);
// const showCartDrawer = ref(true);
const router = inject('router');
const store = inject('store');
const showDialog = ref(false);

const logoutUser = async () => {
  showDialog.value = true;
  await fetch(apiLogout, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: "",
  }).then(async (response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    await setCookie('userLogged', '', 0);
    router.push({ name: 'Sign-in' });
  })
    .catch(() => {
      showDialog.value = false;
    });
};



const openCartDrawer = () => {
  if (store.state.showCartDrawer) {
    store.dispatch('closeCartDrawer');
  } else {
    store.dispatch('openCartDrawer');
  }
}

</script>
