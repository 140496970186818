<template>
  <v-app-bar color="#2fd9b9" height="80">
    <div class="nav test">
      <div class="nav-header">
        <div class="nav-title">
          <a href="https://prasiddhiforest.org/">
            <img style="width:150px" src="https://prasiddhiforest.org/assets/images/prasiddhi-forest.png" alt="">
          </a>
        </div>
      </div>
      <div class="nav-btn" @click="showMobileMenu">
        <label for="nav-check">
          Home
        </label>
      </div>

      <div class="nav-links">
        <ul>
          <li><a :href="`${BASE_PATH}/about.php`" class="nav-link">Know us</a></li>
          <li><a :href="`${BASE_PATH}/our-team.php`">Our Team</a></li>
          <li><a :href="`${BASE_PATH}/tree-plantation.php`">Plantation</a></li>
          <li><a :href="`${BASE_PATH}/csr.php`">Green Collaboration &amp; CSR</a></li>
          <li><a :href="`${BASE_PATH}/change-maker.php`">Be the ChangeMakers</a></li>
          <li><a :href="`${BASE_PATH}/contact.php`">Reach out</a></li>
          <li><a :href="`${BASE_PATH}/blog`">Blog</a></li>
          <li><a :href="`${BASE_PATH}/donate-now.php`">Donate</a></li>
        </ul>
      </div>
    </div>
  </v-app-bar>
</template>
<script setup>
import { BASE_PATH } from "@/components/utils/constant";
const showMobileMenu = () => {
  window.location.href = "https://prasiddhiforest.org/";
}
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>