import { createRouter, createWebHistory } from 'vue-router'
// import { getCookie } from '@/components/utils/utils';

// import accountRouter from './Accounts/index'
import quickPlantation from './QuickPlantation/index'
// import Dashboard from '@/components/Pages/Dashboard/DashboardPage'
// import BuyNewTrees from '@/components/Pages/BuyNewTrees/BuyNewTrees'
// import CheckOut from '@/components/Pages/Checkout/CheckOut'
import PaymentGateway from '@/components/Pages/Checkout/PaymentGateway'
import PaymentSuccess from '@/components/Pages/Checkout/PaymentSuccess'
// import GiftYourTrees from '@/components/Pages/GiftTrees/GiftYourTrees'
// import store from '../store';
import NotFound from '@/components/Pages/ErrorPages/NotFound';
const routes = [
  // ...accountRouter,
  ...quickPlantation,
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: Dashboard,
  // },
  // {
  //   path: '/buy-new-trees',
  //   name: 'buy-new-trees',
  //   component: BuyNewTrees,
  // },
  // {
  //   path: '/checkout',
  //   name: 'checkout',
  //   component: CheckOut,
  // },
  {
    path: '/payment',
    name: 'payment',
    component: PaymentGateway,
  },
  {
    path: '/payment-success',
    name: 'paymentSuccess',
    component: PaymentSuccess,
  },
  // {
  //   path: '/gift-trees',
  //   name: 'gift-trees',
  //   component: GiftYourTrees,
  // },
  {
    path: '/:catchAll(.*)', // Catch all routes that don't match any defined route
    component: NotFound
  }
]

const router = createRouter({ 
  history: createWebHistory(),
  routes 
})

router.beforeEach((to, from, next) => {
  // next()
  if (from.name == 'paymentSuccess' && to.name === 'payment') {
    window.location.href = "https://prasiddhiforest.org/"
  } else {
    next()
  }
  // const publicRoutes = ['Sign-in', 'Sign-up'];
  // const isPublicRoute = publicRoutes.includes(to.name);

  // const isAuthenticated = getCookie('userLogged');
  // console.log(isAuthenticated);

  // if (isPublicRoute || isAuthenticated) {
  //   if (from.path == "/") {
  //     next({ name: 'dashboard' });
  //   } else {
  //     next();
  //   }
  // } else {
  //   next({ name: 'Sign-in' });
  // }
})

export default router
