const state = {
  quickPlantationDetails: {},
}
const mutations = {
  updateQuickPlantationDetails(state, payload) {
    state.quickPlantationDetails = payload;
  }
}

const actions = {
  updateQuickPlantationDetailsActions({commit}, payload) {
    commit('updateQuickPlantationDetails', payload);
  }
}

export default {
  state,
  mutations,
  actions,
}
