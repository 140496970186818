
const state = {
  showCartDrawer: false,
  myCarts: [],
  cartSubTotal: 0,
  project: "",
  treesPurchase: 0,
};

const mutations = {
  // showCartDrawer(state) {
  //   state.showCartDrawer = true;
  // },
  // hideCartDrawer(state) {
  //   state.showCartDrawer = false;
  // },
  // totalCartPrice(state) {
  //   state.cartSubTotal = state.myCarts.reduce((sum, item) => {
  //     return sum + parseFloat(item.price);
  //   }, 0);
  // },
  // addToCartDrawer(state, payload) {
  //   const foundIndex = state.myCarts.findIndex(x => x.id === payload.id);
  //   if (foundIndex !== -1) {
  //     state.myCarts[foundIndex].value = payload.value;
  //   } else {
  //     state.myCarts.push(payload);
  //   }
  // },
  // removeItem(state, payload) {
  //   const index = state.myCarts.indexOf(payload.id);
  //   state.myCarts.splice(index, 1);
  // }
  subTotal(state, payload) {
    state.treesPurchase = payload.treesPurchase;
    state.subTotal = payload.subTotal;
    state.project = payload.project;
    state.userDetails.checkoutDetails.tree_count = payload.treesPurchase; 
    state.userDetails.checkoutDetails.subtotal = payload.subTotal;
  }
};

const actions = {
  addSubTotal({commit}, payload) {
    commit('subTotal', payload);
  }
  // openCartDrawer({ commit }) {
  //   commit('showCartDrawer');
  // },
  // closeCartDrawer({ commit }) {
  //   commit('hideCartDrawer');
  // },
  // addToCart({ commit }, payload) {
  //   commit('addToCartDrawer', payload);
  //   commit('totalCartPrice');
  // },
  // removeItemFromCart({commit}, payload) {
  //   commit('removeItem', payload);
  //   commit('totalCartPrice');
  // }
}

export default {
  state, mutations, actions
};