<template>
  <v-row v-for="(cartItem, index) in store.state.myCarts" :key="`${index}_cart`" :cart-item="cartItem">
    <v-col cols="3">
      <v-img class="ml-2" src="https://clipart-library.com/images/LcdjGd69i.png" width="50" height="50" />
    </v-col>
    <v-col cols="6" class="text-left mt-2">
      <h4>{{ cartItem.tree_name }}</h4>
      <p class="text-medium-emphasis text-caption">{{ cartItem.price }} x {{ cartItem.quantity }} = <span> ₹ {{
        cartItem.price * cartItem.quantity }} </span></p>
    </v-col>
    <v-col cols="3" class="mt-2 pr-4">
      <!-- <h5>{{ cartItem.quantity }} Quanity</h5> -->
      <v-btn @click="removeItemFromCart" variant="flat" size="small" color="lightRed"
        class="text-lowercase mt-2 mr-2">Remove</v-btn>
    </v-col>
  </v-row>
</template>
<script setup>
import { onMounted, defineProps, inject, ref } from "vue";
const store = inject('store');

const props = defineProps(['cartItem']);
const cartItem = ref(props.cartItem);
onMounted(() => {
  console.log('props');
})

const removeItemFromCart = () => {
  store.dispatch('removeItemFromCart', cartItem.value.id);
}
</script>