<template>
  <v-card elevation="3" class="bg-lightGreen">
    <v-img cover :src="treeDetails.image" loading="lazy" :height="140"></v-img>
    <div class="pa-1">
      <p class="text-left text-brown ml-2 mt-1 font-weight-bold" style="height: 48px;">{{ treeDetails.projectName }}
      </p>
      <p class="text-left text-brown ml-2 mt-1">
        <span>No.of Trees Available: </span>
        <span class="font-weight-bold">{{ treeDetails.planted - treeDetails.gifted }}</span>
      </p>
      <p class="text-left text-brown ml-2 mt-1">
        <span>No.of Trees Gifted: </span>
        <span class="font-weight-bold">{{ treeDetails.gifted }}</span>
      </p>
      <div class="d-flex align-center text-brown pa-2">
        <h2 class="me-auto">₹ {{ treeDetails.treePrice }} /-</h2>
        <div class="d-flex flex-row align-center">
          <v-btn @click="removeQuantity(index)" :disabled="quantity == 0" variant="text"
            icon="fas fa-light fa-minus-circle " size="small"></v-btn>
          <p class="font-weight-medium">
            {{ quantity }}
          </p>
          <v-btn @click="addQuantity(index)" variant="text" class="p-15" icon="fas fa-light fa-plus-circle"
            size="small"></v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";
// Use props function to access prop values
const props = defineProps(['treeDetails']);
const quantity = ref(0);
const treeDetails = ref(props.treeDetails);
const emit = defineEmits(['emitProductItem']);

const addQuantity = () => {
  quantity.value += 1;
  emitProductItem();
};
const removeQuantity = () => {
  quantity.value -= 1;
  emitProductItem();
}
const emitProductItem = () => {
  const emittedData = {
    ...treeDetails.value,
    quantity: quantity.value,
  }
  emit('emitProductItem', emittedData)
}
</script>
