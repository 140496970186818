<template>
  <v-container-fluid>
    <v-img :width="auto" aspect-ratio="16/9" cover
      src="https://prasiddhiforest.org/assets/images/donate-banner.png"></v-img>
    <div class="d-flex justify-center">
      <v-card elevation="4" :class="$vuetify.display.xs ? 'pa-6' : 'pa-10'"
        image="https://prasiddhiforest.org/assets/images/Doodle-Bg.png">
        <div class="text-center">
          <v-avatar size="80">
            <v-icon size="60" color="green" icon="fas fa-regular fa-check"></v-icon>
          </v-avatar>
          <p class="pa-4 primary--text" :class="$vuetify.display.xs ? 'text-h4' : 'text-h3'">Payment successfull</p>
        </div>
        <v-row>
          <v-col cols="12" class="pa-1">
            <p class="text-center">Triumph of Sustainability! Planting Success in Prasiddhi Forest
              Foundation</p>
            <blockquote class="font-italic font-weight-black"
              :class="$vuetify.display.xs ? 'text-justify' : 'text-center'" style="color: #40415e;">
              In the lush haven of Prasiddhi Forest Foundation, the act of planting a tree encapsulates our shared
              triumph
              in environmental stewardship. This sapling, carefully nestled into the earth, represents not just a
              solitary
              endeavor but a collective commitment to nurturing our planet's future
            </blockquote>
          </v-col>
        </v-row>
        <div class="d-flex justify-center mt-5">
          <v-card :width="$vuetify.display.xs ? '85vw' : '50vw'" class="text-center pa-5">
            <v-card-text class="text-center text-h6 font-weight-regular">Your order details</v-card-text>
            <CartOrderDetails :cart-list="store.state.quickPlantationDetails.productList" title="Your Order Details" />
          </v-card>
        </div>
        <div class="mt-5 text-center">
          <p class=" text-h4">Thank you so much dear {{ store.state.quickPlantationDetails.name
            }}</p>
          <p class="text-center text-h6 font-weight-regular">Expect to receive the certificate and invoice details in
            your inbox shortly.</p>
        </div>
        <v-card-actions class="justify-center">
          <a href="https://prasiddhiforest.org/">
            <v-btn color="secondary" append-icon="fas fa-light fa-tree" size="large" variant="flat"
              class="text-capitalize ma-5">Visit Our Webiste</v-btn></a>

        </v-card-actions>
      </v-card>
    </div>
  </v-container-fluid>
</template>
<script setup>
import { onUnmounted } from "vue";
import { useStore } from 'vuex'
const store = useStore();
onUnmounted(() => {
  store.dispatch('disablePaymentAction');
});
import CartOrderDetails from "../BuyNewTrees/components/CartOrderDetails.vue";
</script>