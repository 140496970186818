<template>
  <v-navigation-drawer location="right" temporary v-model="cartStatus" width="350">
    <template v-slot:prepend>
      <div>
        <v-btn @click="closeCartDrawer(index)" variant="text" icon="fas fa-light fa-xmark" color=""></v-btn>
      </div>
      <v-divider></v-divider>
      <div class="mt-3">
        <CartDrawerItem  />
      </div>
    </template>

    <template v-slot:append>
      <v-card>
        <v-card-actions>
          <p>Subtotal {{ store.state.cartSubTotal }}</p>
          <v-spacer></v-spacer>
          <v-btn color="greenColor" class="pa-4 primary--text" size="x-large" variant="flat"
            @click="goToCheckout" append-icon="fas fa-light fa-cart-shopping">
            Checkout
          </v-btn>
        
        </v-card-actions>
      </v-card>

    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { inject, computed } from "vue";
const store = inject('store');
const router = inject('router');
import CartDrawerItem from "./CartDrawerItem.vue";
const cartStatus = computed(() => store.state.showCartDrawer);
// const getCartDetails = computed(() => store.getters.getCartDetails);

const closeCartDrawer = (() => {
  store.dispatch('closeCartDrawer');
});

const goToCheckout = (() => {
  closeCartDrawer();
  router.push({name:'checkout'});
})
</script>
