<template>
  <v-container fluid class="quick-plantation-container">
    <v-img aspect-ratio="16/9" cover src="https://prasiddhiforest.org/assets/images/quick-plantation.webp"></v-img>
    <v-card class="pa-3" image="https://prasiddhiforest.org/assets/images/Doodle-Bg.png">
      <div v-if="!showUnexectedErrorSection">
        <p class="text-h6 pa-3 font-weight-medium text-medium-emphasis"
          :class="$vuetify.display.xs ? 'text-justify' : 'text-center'">In the pursuit of a brighter
          future, let's sow the seeds of sustainability today. By planting trees, we foster resilience, preserve
          biodiversity, and cultivate hope for generations to come</p>
        <!-- <v-row> -->
        <v-card-title class="text-center text-h6">Pick Your Planting Projects</v-card-title>
        <!-- </v-row> -->
        <v-row class="pt-5 pb-5">
          <v-col cols="12" sm="6" md="3" v-for="(projectItem, projectIndex) in plantationProjects" :key="projectIndex">
            <ProductCard @emitProductItem="AddtoCart" :tree-details="projectItem" />
          </v-col>
        </v-row>
        <v-form class="bg-white pa-4" v-model="quickPlantationForm" @submit.prevent="submitQuickPlantationForm">
          <v-row :class="$vuetify.display.xs ? 'flex-column' : 'flex-row'">
            <v-col md="7">
              <v-card-title class="mb-2">User Details</v-card-title>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field :rules="yourNameRules" prepend-inner-icon="fas fa-light fa-user" clearable
                    variant="outlined" rounded density="compact" outline v-model="yourname" label="Enter your name *"
                    required></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field :rules="emailRules" prepend-inner-icon="fas fa-light fa-user" clearable
                    variant="outlined" rounded density="compact" outline v-model="email" label="Enter email address"
                    required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="pb-0">
                  <v-select v-model="countryCode" :rules="countryCodeRules" label="Country code*" width="10"
                    :items="['+91']" density="compact" variant="underlined"></v-select>
                </v-col>
                <v-col cols="12" md="5" class="pb-0">
                  <v-text-field type="number" :rules="numberRules" prepend-inner-icon="fas fa-light fa-mobile-screen"
                    clearable variant="outlined" rounded density="compact" outline v-model="phoneNumber"
                    hide-spin-buttons label="Phone Number*" required></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="pb-0">
                  <v-text-field class=" " :rules="panNumberRules" prepend-inner-icon="fas fa-light fa-id-card" clearable
                    variant="outlined" rounded density="compact" outline v-model="panNumber" label="Pan Number"
                    required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field :rules="locationRules" prepend-inner-icon="fas fa-light fa-location-dot" clearable
                    variant="outlined" rounded density="compact" outline v-model="city" label="Enter your city"
                    required></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="">
                  <v-select v-model="howSocialKnow" label="How did you hear about us" width="10"
                    :items="['Social Media', 'Friends & Family', 'Google']" density="compact"
                    variant="underlined"></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="5">
              <CartOrderDetails :cart-list="addToCardList" />
              <v-card-title class="text-danger text-body-2">{{ apiPostPlantationErrorMessage }}</v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn :disabled="!addToCardList.length" type="submit" rounded="lg" variant="elevated" color="primary"
                  class="pl-10 pr-10 mb-5 mb-sm-0">
                  Check out
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <div v-else class="mt-10 mr-5 ml-5 mb-10">
        <v-row class="flex-column align-center">
          <v-btn icon="fa-solid fa-circle-exclamation" size="x-large" color="danger">
          </v-btn>
          <p class="text-danger text-center text-h6 pa-5">We're sorry, but we're unable to display the list of projects
            at the moment due to an unexpected error. Please try again later.</p>
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>
<script setup>
import { ref, onMounted, inject } from "vue";
import { apiGetQuickPlantationProjects, apiPostQuickPlantationProjects, defaultErroMessage } from '../../utils/constant';
import ProductCard from '../BuyNewTrees/components/ProductCard.vue';
import CartOrderDetails from '../BuyNewTrees/components/CartOrderDetails.vue';
const store = inject('store');
const router = inject('router');
const apiPostPlantationErrorMessage = ref("");
const plantationProjects = ref([]);
const quickPlantationForm = ref(false);
const showUnexectedErrorSection = ref(false);
const yourname = ref("");
const city = ref("");
const email = ref("");
const phoneNumber = ref("");
const howSocialKnow = ref("");
const addToCardList = ref([]);
const subTotalDetails = ref({});
const countryCode = ref("");
const panNumber = ref("");
const yourNameRules = ref([
  value => {
    return value && value.length > 2 ? true : 'Enter your name'
  },
]);
const numberRules = ref([
  value => {
    return value && value.length == 10 ? true : 'Enter your valid phone number'
  },
]);
const emailRules = ref([
  value => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Please enter your valid email'
  },
]);
const countryCodeRules = ref([
  value => {
    return value && value.length > 2 ? true : 'Enter select country code'
  },
]);
const locationRules = ref([
  value => {
    return value && value.length > 2 ? true : 'Enter your location'
  },
]);
const panNumberRules = ref([
  value => {
    if (subTotalDetails.value.rate > 5000) {
      return value && value.length && /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(value) ? true : 'Pan Number is required if the amount is more than 1000 rupees'
    }
    return true;
  },
]);
const fetchQuickPlantationProjects = async () => {
  store.dispatch('openLoader');
  await fetch(apiGetQuickPlantationProjects, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
        plantationProjects.value = data.projects;
      } else {
        showUnexectedErrorSection.value = true;
      }
    })
    .catch((e) => {
      console.log(e);
      showUnexectedErrorSection.value = true;
    }).finally(() => {
      store.dispatch('closeLoader');
    });
}

const updateSubTotal = () => {
  subTotalDetails.value = {
    "quantity": addToCardList.value.reduce((x, y) => x + y.quantity, 0),
    "rate": addToCardList.value.reduce((x, y) => x + (y.quantity * y.treePrice), 0)
  }
}

const AddtoCart = (emittedValue) => {
  const itemToUpdate = addToCardList.value.find((x) => x.id == emittedValue.id);
  if (itemToUpdate) {
    if (emittedValue.quantity) {
      itemToUpdate.quantity = emittedValue.quantity
    } else {
      const cartItemToRemove = addToCardList.value.findIndex((cartItem) => cartItem.id === emittedValue.id);
      addToCardList.value.splice(cartItemToRemove, 1);
    }
  } else {
    addToCardList.value.push(emittedValue);
  }
  updateSubTotal();
}

const submitQuickPlantationForm = async () => {
  const payloadData = {
    name: yourname.value,
    city: city.value,
    email: email.value,
    countryCode: countryCode.value,
    phoneNumber: phoneNumber.value,
    howSocialKnow: howSocialKnow.value,
    productList: addToCardList.value,
    panNumber: panNumber.value
  };
  if (quickPlantationForm.value) {
    store.dispatch('openLoader');
    await fetch(apiPostQuickPlantationProjects, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payloadData),
    }).then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
      .then(async (data) => {
        if (data.success) {
          const payDetails = {
            ...data,
            email: email.value,
            phoneNumber: phoneNumber.value
          };
          store.dispatch('updateQuickPlantationDetailsActions', payloadData);
          store.dispatch('updatPaymentDetailsAction', payDetails);
          router.push({ name: 'payment' });
        } else {
          apiPostPlantationErrorMessage.value = defaultErroMessage;
          console.log("some error in sucess");
        }
      })
      .catch((e) => {
        console.log(e);
        apiPostPlantationErrorMessage.value = defaultErroMessage;
      }).finally(() => {
        store.dispatch('closeLoader');
      })
  }
};

onMounted(() => {
  fetchQuickPlantationProjects();
});


</script>

<style scoped>
.quick-plantation-container {
  background-image: linear-gradient(to right, #3dd5e0, #2ad7d6, #23d8c9, #2ed9b9, #41d9a8);
  max-width: 100vw;
}
</style>