export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

// Accounts API
export const apiCreateAnAccount = `${API_BASE_URL}/accounts/create-an-account`;
export const apiLogin = `${API_BASE_URL}/accounts/login`;
export const apiLogout = `${API_BASE_URL}/accounts/logout`;
export const apiForgotPassword = `${API_BASE_URL}/accounts/forgot-password`;

// Quick plantation API
export const apiGetQuickPlantationProjects = `${API_BASE_URL}/quick-plantation/getProjects`;
export const apiGetQuickPlantationDetails = `${API_BASE_URL}/quick-plantation/get-quick-plantation`;
export const apiPostQuickPlantationProjects = `${API_BASE_URL}/quick-plantation/post-quick-plantation`;

// Payment verification API
export const apiPostPaymentVerification = `${API_BASE_URL}/payment/verification`;


export const apiGetTrees = `${API_BASE_URL}/api/get-trees`;
export const apiCheckOut = `${API_BASE_URL}/api/check-out`;
export const apiApplyCoupon = `${API_BASE_URL}/api/check-apply-coupon`;
export const apiGetCheckOut = `${API_BASE_URL}/api/get-check-out`;

export const defaultErroMessage = "we encountered an unexpected error. Please try again later.";

export const doodleBg = "https://prasiddhiforest.org/assets/images/Doodle-Bg.png";
export const logo404 = "https://prasiddhiforest.org/assets/images/404-logo.svg";

export const BASE_PATH = "https://prasiddhiforest.org";
