const state = {
  paymentDetails: {},
};

const mutations = {
  updatPaymentDetails(state, payload) {
    state.paymentDetails = payload;
  }
}

const actions = {
  updatPaymentDetailsAction({commit},  payload) {
    commit('updatPaymentDetails', payload);
    commit('enablePaymentAction');
  }
}

export default {
  state, mutations, actions
}
