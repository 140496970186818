import { createStore } from "vuex";
import addToCart from "./addToCart";
import paymentGateway from './paymentGateway';
import quickPlantation from './quickPlantation';

const state = {
  isLoggedIn: false,
  userDetails: {},
  showLoader: false,
  enablePayment: false,
};

const mutations = {
  addLoggedIn(state, user) {
    state.isLoggedIn = true;
    state.userDetails = user;
  },
  showLoader(state) {
    state.showLoader = true;
  },
  closeLoader(state) {
    state.showLoader = false;
  },
  enablePaymentVal(state) {
    state.enablePayment = true;
  },
  disablePaymentVal(state) {
    state.enablePayment = false;
  }
};

const actions = {
  userLoggedIn({ commit }, payload) {
    commit('addLoggedIn', payload);
  },
  openLoader({ commit }) {
    commit('showLoader');
  },
  closeLoader({ commit }) {
    commit('closeLoader');
  },
  enablePaymentAction({ commit }) {
    commit('enablePaymentVal');
  },
  disablePaymentAction({ commit }) {
    commit('disablePaymentVal');
  },
}

const store = createStore({
  state: {
    ...state,
    ...addToCart.state,
    ...paymentGateway.state,
    ...quickPlantation.state,
  },
  mutations: {
    ...mutations,
    ...addToCart.mutations,
    ...paymentGateway.mutations,
    ...quickPlantation.mutations,
  },
  actions: {
    ...actions,
    ...addToCart.actions,
    ...paymentGateway.actions,
    ...quickPlantation.actions,
  },
});

export default store;