import '@fortawesome/fontawesome-free/css/all.css' //
import { createVuetify } from "vuetify";
import "vuetify/styles";
import '@/styles/fonts.scss';
import { aliases, fa } from 'vuetify/iconsets/fa'

import * as components from "vuetify/components";
import * as directives from "vuetify/directives";


export default createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#698020",
          primary2: "#2fd9b9",
          secondary: "#4e73df",
          danger: "#D22B2B",
          greenColor: "#1cc88a",
          lightRed: "#de5f59",
          grey: "#ddd",
          lightgrey:"#626262",
          brown:"#523829",
          white: "#e9fd8263"
        }
      },
    },
  },
});
