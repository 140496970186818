<template>
  <v-container fluid class="quick-plantation-container">
    <v-img aspect-ratio="16/9" cover src="https://prasiddhiforest.org/assets/images/quick-plantation.webp"></v-img>
    <v-card class="pa-5" image="https://prasiddhiforest.org/assets/images/Doodle-Bg.png">
      <div>
        <p class="text-center text-h4 text-brown">Your tree(s) has been planted right here!</p>
        <v-row class="align-center" :class="$vuetify.display.xs ? 'ml-1 mr-1' : 'ma-4'">
          <v-col v-if="quickPlantationDetails && quickPlantationDetails.plantationDetails">
            <table class="ma-2 order-table">
              <tr>
                <td class="text-right font-weight-medium" width="50%">
                  Name:
                </td>
                <td class="text-left" width="50%">
                  {{ quickPlantationDetails.plantationDetails.name }}
                </td>
              </tr>
              <tr>
                <td class="text-right font-weight-medium" width="50%">Total Amount:</td>
                <td class="text-left" width="50%">₹{{ quickPlantationDetails.plantationDetails.totalAmount }}/-</td>
              </tr>
              <tr>
                <td class="text-right font-weight-medium" width="50%">Payment Id:</td>
                <td class="text-left" width="50%">{{ quickPlantationDetails.plantationDetails.paymentId }}</td>
              </tr>
              <tr>
                <td class="text-right font-weight-medium" width="50%">Invoice No:</td>
                <td class="text-left" width="50%">
                  <span>{{ quickPlantationDetails.plantationDetails.invoiceNo }}</span>
                </td>
              </tr>
              <tr>
                <td class="text-right font-weight-medium" width="50%">Invoice Link:</td>
                <td class="text-left" width="50%">
                  <v-btn density="compact" :href="qpCertInvoiceLinks.invoiceLink" color="secondary">Download
                    Invoice</v-btn>
                </td>
              </tr>
              <tr>
                <td class="text-right font-weight-medium" width="50%">Certificate Link:</td>
                <td class="text-left" width="50%">
                  <v-btn density="compact" :href="qpCertInvoiceLinks.certificateLink"
                    color="secondary text-white">Download Certificate</v-btn>
                </td>
              </tr>
            </table>
          </v-col>

          <v-col class="pa-4 ma-0">
            <v-img aspect-ratio="16/9" cover class="rounded mx-auto" width="40vh"
              src="https://prasiddhiforest.org/assets/images/1.png"></v-img>
          </v-col>
          <v-col class="">
            <p class="text-subtitle-1 pa-5 font-weight-medium text-medium-emphasis text-justify">The sapling now takes
              root, nestled in the
              earth
              where it will grow strong and tall. With each passing season, it will stretch towards the sun, its
              branches
              reaching out in a silent dance with the wind. As time weaves its tapestry, this tree will stand as a
              testament
              to the enduring cycle of life and the promise of renewal.</p>
          </v-col>
        </v-row>

        <v-card :class="$vuetify.display.xs ? 'mb-6 ml-1 mr-1' : 'ma-4'"
          v-for="(plantationItem, plantationIndex) in quickPlantationDetails.projectDetails" :key="plantationIndex">
          <div class="text-left card-background">
            <p class="pl-2 pt-1 text-subtitle-2">
              <span class="font-weight-bold">Project Name: </span>
              <span class="">{{ plantationItem.projectName }}</span>
            </p>
            <v-divider></v-divider>
            <p class="pl-2 pt-2 text-subtitle-2">
              <span class="font-weight-bold">Project Description: </span>
              <span class="">{{ plantationItem.ProjectDetails }}</span>
            </p>
          </div>
          <v-row class="flex-column">
            <v-col>
              <div class="map" :id="`map${plantationIndex}`" ref="map"></div>
            </v-col>
            <v-col>
              <v-list lines="one">
                <v-list-item>
                  <v-list-item-title class="font-weight-bold">No. of Trees Planted: <span
                      class="font-weight-bold text-subtitle-1 text-secondary text-high-emphasis mb-1">
                      {{ plantationItem.planted }} Trees
                    </span></v-list-item-title>
                  <v-divider></v-divider>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title class="font-weight-bold">No of Trees Gifted: <span
                      class="font-weight-bold text-subtitle-1 text-secondary text-high-emphasis mb-1">
                      {{ plantationItem.gifted }} Trees
                    </span></v-list-item-title>
                  <v-divider></v-divider>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row v-if="plantationItem.photos.length" class="pl-3 pb-2 pr-3">
            <v-col cols="12">
              <p class="text-left text-h6 pl-2 pt-2 text-secondary">Project Photos:</p>
            </v-col>
            <v-col :cols="$vuetify.display.xs ? 6 : 3" v-for="(photoItem, photoIndex) in plantationItem.photos"
              :key="photoIndex">
              <v-img cover class="rounded" :src="photoItem.image"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-card>
  </v-container>
</template>
<script setup>
import { onMounted, ref, inject } from "vue";
import { useRoute } from 'vue-router'
const router = useRoute();
const store = inject("store");
import { Loader } from "@googlemaps/js-api-loader";
import { apiGetQuickPlantationDetails, BASE_PATH } from './../../utils/constant';
const plantationId = ref("");
const quickPlantationDetails = ref("");
const qpCertInvoiceLinks = ref({});

onMounted(() => {
  plantationId.value = router.params.id;
  getQuickPlantation();
});

const getQuickPlantation = async () => {
  const payloadData = {
    id: plantationId.value,
  };
  store.dispatch('openLoader');
  await fetch(apiGetQuickPlantationDetails, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payloadData),
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
        quickPlantationDetails.value = data.data;
        qpCertInvoiceLinks.value.invoiceLink = `${BASE_PATH}/quickplantation${data.data.plantationDetails.invoicePath}`;
        qpCertInvoiceLinks.value.certificateLink = `${BASE_PATH}/quickplantation${data.data.plantationDetails.certificatePath}`;
        initMapLoader(data.data.projectDetails);

      }
    })
    .catch((e) => {
      console.log(e);
    }).finally(() => {
      store.dispatch('closeLoader');
    });
};

const initMapLoader = async (projectItems) => {
  for (let index = 0; index < projectItems.length; index++) {
    const projectItem = projectItems[index];
    const position = { lat: Number(projectItem.lat), lng: Number(projectItem.lng) };
    const map = `map${index}`;
    displayMap(position, map);

  }
}

const displayMap = async (position, selector) => {
  const loader = new Loader({
    apiKey: 'AIzaSyDnJwBsqTorLgnjw5nw2YssbBH3trvaGF4',
    version: "weekly",
  });
  const mapOptions = {
    center: position,
    zoom: 13
  };
  try {
    const maps = await loader.importLibrary("maps");
    const mapObj = await new maps.Map(document.getElementById(selector), mapOptions);
    const { Marker } = await loader.importLibrary("marker");
    new Marker({
      position: position,
      map: mapObj,
    });
  } catch (error) {
    console.error("Error loading Google Maps:", error);
  }
}
</script>
<style scoped>
.map {
  width: 100%;
  height: 45vh;
}

.card-background {
  background-color: #2fd9b9
}
</style>