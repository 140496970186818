import { createApp } from "vue";
import App from "./App";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import '../src/styles/common.scss';
import EllipsisDirective from "./components/utils/elipsis";

const app = createApp(App);
app.provide('router', router);
app.use(vuetify);
app.use(router);
app.use(store);
app.directive('ellipsis', EllipsisDirective);

app.provide('store', store);
app.mount("#app");
