import quickPlantation from '@/components/Pages/QuickPlantation/QuickPlantation.vue';
import ViewQuickPlantation from '@/components/Pages/QuickPlantation/ViewQuickPlantation.vue';

const quickPlantationRouter = [
  {
    path: '/',
    name: 'quick-plantation',
    component: quickPlantation,
  },
  {
    path: '/view-quick-plantation/:id',
    name: 'viewQuickPlantation',
    component: ViewQuickPlantation,
  },
]

export default quickPlantationRouter;
