<template>
  <v-container>
  </v-container>
</template>
<script setup>
import { inject, onMounted } from "vue";
const store = inject('store');
import { apiPostPaymentVerification } from "./../../utils/constant";
const router = inject('router');

onMounted(() => {
  loadRazorpayScript().then(() => {
    const amount = Number(store.state.paymentDetails.amount) * 100;
    const options = {
      "key": store.state.paymentDetails.payKey,
      "amount": amount,
      "currency": "INR",
      "description": "Prasiddhi Forest Foundation",
      "image": "https://prasiddhiforest.org/assets/images/pra_about.png",
      "order_id": store.state.paymentDetails.orderId,
      "prefill": {
        "email": store.state.paymentDetails.email,
        "contact": store.state.paymentDetails.phoneNumber,
      },
      "handler": ((response) => {
        updatePaymentStatus(response);
      }),
      "modal": {
        "ondismiss": () => {
          router.push(router.options.history.state.back);
        }
      }
    };
    const openRazorPay = window.Razorpay(options);
    openRazorPay.open();
  })
})
const loadRazorpayScript = () => {
  return new Promise((resolve, reject) => {
    // Create a script element
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';

    // Set up the onload event to resolve the promise when the script is loaded
    script.onload = resolve;

    // Set up the onerror event to reject the promise if there is an error loading the script
    script.onerror = reject;

    // Append the script element to the document's head
    document.head.appendChild(script);
  });
};

const updatePaymentStatus = async (paymentResponse) => {
  const payload = {
    productList: store.state.quickPlantationDetails.productList,
    ...paymentResponse,
    ...store.state.paymentDetails,
  }
  await fetch(apiPostPaymentVerification, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      if (data.success) {
        store.dispatch('enablePaymentAction');
        router.push({name: 'paymentSuccess'});
      }
    })
    .catch((e) => {
      console.log(e)
    });
}

</script>