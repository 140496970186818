<template>
  <v-card-title class="mb-2">{{ title }}</v-card-title>
  <table class="cart-table">
    <thead>
      <tr>
        <th class="text-left" width="45%">
          Projects
        </th>
        <th class="text-left" width="20%">
          No.of Trees
        </th>
        <th class="text-left" width="15%">
          Quantity
        </th>
        <th class="text-right" width="20%">
          Rate
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(cartItems, cartIndex) in cartList" :key="cartIndex" class="text-left">
        <td>{{ cartItems.projectName }}</td>
        <td>{{ cartItems.quantity }}</td>
        <td>{{ cartItems.treePrice }}</td>
        <td class="text-right">₹{{ cartItems.quantity * cartItems.treePrice }} /-</td>
      </tr>
    </tbody>
    <thead>
      <tr>
        <th class="text-left">
          Total
        </th>
        <th class="text-left">
          {{ updateSubTotal.quantity }}
        </th>
        <th class="text-left">

        </th>
        <th class="text-right">
          ₹{{ updateSubTotal.rate }} /-
        </th>
      </tr>
    </thead>
  </table>
</template>
<script setup>
import { defineProps, ref, computed } from "vue";
const props = defineProps(['cartList']);
const cartList = ref(props.cartList);
const title = ref(props.title);
const updateSubTotal = computed(() => {
  if (cartList.value) {
    return {
    "quantity": cartList.value.reduce((x, y) => x + y.quantity, 0),
    "rate": cartList.value.reduce((x, y) => x + (y.quantity * y.treePrice), 0)
  }
  } else {
    return []
  }
})
</script>